.wrapPortfolio {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    height: 100%;
    margin-bottom: 80px;
    @media(max-width: 1030px){
        width: 95%;
        margin-bottom: 50px;
    }
    .portfolio {
        min-width: 920px;
        display: flex;
        box-shadow: 0px 10px 13px 3px rgba(0, 0, 0, 5);
        border-radius: 14px;
        background-color: white;
        padding: 20px;
        background-color: rgba(238, 163, 23, 0.1);
        @media(max-width: 1030px){
            min-width: 0px;
        }
        @media(max-width: 520px){
            padding: 0px;
        }
        .wrapImg {
            display: flex;
            flex-direction: column;
            width: 100%;
            @media(max-width: 520px){
                padding-top: 30px;
            }
            .blocImg {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-around;
                @media(max-width: 520px){
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .block {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 6px;
                    margin-top: 8px;
                    //padding-left: 5px;
                    .text {
                        font-size: 26px;
                        color: #4F443B;
                        font-weight: 600;
                        @media(max-width: 720px){
                            font-size: 18px;
                            margin: 3px;
                        }
                        @media(max-width: 520px){
                            text-align: center;
                        }
                    }
                    .img {
                        border-radius: 12px;
                        box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 5);
                        @media(max-width: 1030px){
                            width: 200px;
                            height: 113px;
                        }
                        @media(max-width: 720px){
                            border-radius: 10px;
                            margin: 3px;
                        }
                        @media(max-width: 520px){
                            width: 100%;
                            height: auto;
                           
                        }
                        &:hover {
                            box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
                        }
                        &:active {
                            box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
                            animation: scaling 1s;
                        }
                        &:focus {
                            box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
                        }
                    }
                    .wrapImg {
                        box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 5);
                        border-radius: 12px;
                        width: 290px;
                        height: 160px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: white;
                        cursor: pointer;
                        @media(max-width: 1030px){
                            width: 200px;
                            height: 100px;
                        }
                        @media(max-width: 720px){
                            width: 98px;
                            height: 58px;
                            border-radius: 10px;
                            margin: 3px;
                        }
                        @media(max-width: 520px){
                            width: 296px;
                            height: auto;
                            padding-top: 0;
                            
                        }
                        &:hover {
                            box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1); 
                        }
                        &:active {
                            box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
                            animation: scaling 1s;
                        }
                    }
                    
                }
            }
        }
    }  
}

@keyframes scaling {
	0% {
	  transform:scale(0);
	}
	100% {
	 transform:scale(1)
	}
}