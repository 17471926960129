@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
.wrapAlert {
    width: 260px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 3px solid green;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    word-wrap: break-word;
    align-items: center;
    animation: scalingAlert 1s;
    h2 {
      color: green;
      font-size: 26px;
      font-family: 'Limelight', cursive;
      margin-top: 16px;
    }
    .wrapTitle {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .textAlert {
        font-size: 20px;
        font-family: 'Limelight', cursive;
        color: gray;
        font-weight: 500;
        padding-bottom: 18px;
        margin-top: 12px;
    }
    .smile {
      font-size: 35px;
      color: green;
      margin-bottom: 16px;
    }
  }
  @keyframes scalingAlert {
      From {
          -webkit-transform: scale(0);
      }
      To {
          -webkit-transform: scale(1.0);
      }
  }