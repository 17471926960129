
.wrapSteps {
    width: 85%;
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    background-color: #ffffff;
    border-radius: 12px;
    margin-bottom: 18px;
    @media(max-width: 1030px){
        width: 95%;
        margin-top: 20px;
    }
    .stepsTitle {
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 1;
        .title {
            font-size: 34px;
            color: #de3a11;
            font-family: 'Lobster', cursive;
            margin-top: 18px;
            font-weight: 600;
            @media(max-width: 1030px){
              font-size: 26px;
            }
          }
       .titleReact {
        width: 460px;
        height: 46px;
        margin-top: 24px;
        @media(max-width: 520px){
            width: 320px;
            height: auto;
        }
   }
    }
    .stepsBlock {
        margin-top: 24px;
        margin-left: 60px;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
        margin-bottom: 18px;
        z-index: 1;
        @media(max-width: 1000px){
            margin-left: 50px;
            margin-right: 30px;
        }
        @media(max-width: 520px){
            margin-left: 20px;
            margin-right: 10px;
        }
        .steps {
            display: flex;
            flex-direction: row;
            margin-bottom: 40px;
            @media(max-width: 1030px){
                display: block;
            }
            .circleReact {
                border-radius: 18px;
                box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 5);
                @media(max-width: 1030px){
                    border-radius: 10px;
                    float: left;
                    margin-right: 20px;
                    width: 110px;
                    height: 62px;
                }
            }
        }
        .text {
            margin-left: 30px;
            color: #4F443B;
            font-weight: 500;
            font-size: 22px;
            .textBold {
                color: #4F443B;
                font-size: 23px;
                font-weight: 600;
                @media(max-width: 520px){
                    margin-left: 0px;
                    padding-left: 0px;
                    font-size: 20px;
                }
            }
            @media(max-width: 1000px){
                margin-top: 2px;
            }
            @media(max-width: 520px){
                margin-left: 20px;
                margin-top: 0px;
                font-size: 17px;
            }
            .textReact {
                font-size: 22px;
                color: #4F443B;
                @media(max-width: 520px){
                    font-size: 17px;
                }
            }
        }
        .description {
            font-size: 22px;
            color: #4F443B;
            font-weight: 500;
            margin-bottom: 28px;
            @media(max-width: 520px){
                font-size: 17px;
            }
        }
    }
}
.pageSpeed {
    color: #de3a11;
    text-decoration: underline;
    cursor: pointer;
}
.wrapModal {
    width: 940px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 253, 208, 0.9);
    border: 2px solid #4F443B;
    border-radius: 8px;
    @media(max-width: 1030px){
        width: 740px;
    }
    @media(max-width: 520px){
        width: 300px;
    }
    .pageSpeedImg {
        margin: 20px;
        @media(max-width: 1030px){
            margin: 10px;
            width: 720px;
            height: 377px;
        }
        @media(max-width: 520px){
            width: 280px;
            height: 146px;
        }
    }
    .close{
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        @media(max-width: 520px){
            right: 4px;
            top: 4px;
        }
      }
  }