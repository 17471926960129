@import url('https://fonts.googleapis.com/css2?family=Limelight&display=optional');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=optional');

h1, h2{
  padding: 0;
  margin: 0;
}
.wrapShowHeader {
    transition: all 1s ease;
    margin-top: 0px
}
.wrapCloseHeader {
    margin-top: -125px;
    transition: all 1s ease;
}
.burger {
    position: absolute;
    left: 0;
    font-size: 32px;
    color: #4F443B;
    z-index: 10;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background-color: #fffcc8;
  @media(max-width: 520px){
    max-width: 100vh;
  }
  .wrapOrder {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    @media(max-width: 1030px){
      width: 95%;
    }
    @media(max-width: 520px){
      flex-direction: column;
    }
    .wrapContent {
      display: block;
      @media(max-width: 520px){
        flex-direction: column;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
    .mainTitle {
      opacity: 0;
      position: absolute;
      top: -20px;
    }
    .wrapImg {
      float: left;
      font-size: 32px;
      font-weight: bold;
      color: #4F443B;
      @media(max-width: 520px){
        width: 100%;
        float: none;
        display: flex;
        justify-content: center;
      }
      .img {
        margin-top: 20px;
        margin-right: 20px;
        cursor: pointer;
        @media(max-width: 520px){
          float: none;
          width: 280px;
          height: 254px;
        }
      }
      .riseImg {
        width: 280px;
        height: 254px;
        margin-top: 20px;
        margin-right: 20px;
        animation: scaling 0.4s;
        cursor: pointer;
        @media(max-width: 520px){
          width: 280px;
          height: 254px;
        }
      }
    }
    .title {
      font-size: 34px;
      color: #4F443B;
      font-weight: 500;
      font-family: 'Limelight', cursive;
      font-display: swap;
      margin-top: 18px;
      margin-bottom: 8px;
      text-align: center;
      font-weight: 600;
      @media(max-width: 1030px){
        font-size: 28px;
      }
      @media(max-width: 520px){
        font-size: 22px;
        margin-top: 18px;
        margin-bottom: 8px;
      }
      .titleColor {
        font-size: 34px;
        color: #de3a11;   //  #af0000;    
        @media(max-width: 1030px){
          font-size: 28px;
        }
        @media(max-width: 520px){
          font-size: 22px;
        }
      }
    } 
    .content {
      color: #4F443B;
      font-weight: 500;
      font-size: 22px;
      font-family: 'Lobster', cursive;
      font-display: swap;
      .app {
        color: #de3a11;
        font-size: 23px;
        padding-left: 3px;
        padding-right: 2px;
        font-display: swap;
        font-weight: 600;
        @media(max-width: 520px){
          font-size: 17px;
        }
      }
      @media(max-width: 520px){
        font-size: 16px;
        padding-right: 20px;
      }
    }
    .contentResetMobile {
      color: #4F443B;
      font-weight: 500;
      font-size: 22px;
      font-family: 'Lobster', cursive;
      font-display: swap;
      @media(max-width: 520px){
        display: none;
      }
    }
  }
  .wrapBtn {
    display: flex;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    margin-top: 20px;
    @media(max-width: 1030px){
      width: 95%;
    }
    @media(max-width: 520px){
      flex-direction: column;
      align-items: center;
    }
    .btn{
      width: 240px;
      height: 50px;
      margin-bottom: 8px;
      margin-top: 15px;
      border: none; 
      box-shadow: 0px 3px 5px 2px rgba(222, 58, 17, 1);
      border-radius: 8px;
      background-color: #fffcc8;
      font-weight: 600;
      color: gray;
      cursor: pointer;
      font-size: 32px;
      font-family: 'Limelight', cursive;
      font-display: swap;
      color: #de3a11;  
      font-display: swap;
      @media(max-width: 1030px){
        width: 260px;
        height: 45px;
        font-size: 28px;
      }
      @media(max-width: 520px){
        width: 220px;
        height: 45px;
        font-size: 24px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      &:hover {
        color: #fb6a16;
      }
      &:active {
        box-shadow: 0 5px gray;
        transform: translateY(8px);
      }
    }
  }
}

.wrapModal {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 253, 208, 0.9);
  border: 2px solid #fb6a16;
  border-radius: 8px;
  padding-left: 20px;
  padding: 20px;
  .wrapTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    .tytle {
      font-weight: 600;
      font-size: 22px;
      font-family: 'Lobster', cursive;
      font-display: swap;
      color: rgb(42, 46, 46);
    }
    .close{
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
  } 
  .label {
    margin-top: 1rem;
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 500;
    color: #de3a11;
    font-family: 'Lobster', cursive;
    font-display: swap;
  }
  .textarea {
    width: 280px;
    height: 100px;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 2px solid rgba(67, 79, 82);
    font-weight: 500;
    font-size: 18px;
    font-family: 'Lobster', cursive;
    font-display: swap;
    color: rgb(42, 46, 46);
    background-color: rgba(238, 163, 23, 0);
  }
}
.wrapDiscount {
  width: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 253, 208, 0.9);
  box-shadow: 0px 3px 5px 2px rgba(222, 58, 17, 1);
  border-radius: 16px;
  padding: 20px;
  @media(max-width: 520px){
    width: 96%;
  }
  .title {
    font-weight: 700;
    font-size: 34px;
    font-family: 'Limelight';
    font-display: swap;
    color: #de3a11;
    margin-bottom: 14px;
    @media(max-width: 520px){
      font-size: 30px;
    }
  }
  .textDiscount {
    font-weight: 600;
    font-size: 26px;
    font-family: 'Lobster';
    font-display: swap;
    color: #4F443B;
    @media(max-width: 520px){
      font-size: 24px;
    }
  }
  .btnDiscount{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 46px;
    margin-bottom: 10px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #fffcc8;
    font-weight: 600;
    color: gray;
    cursor: pointer;
    font-size: 28px;
    font-family: 'Lobster';
    color: #de3a11;  
    box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
    @media(max-width: 520px){
      width: 148px;
      height: 45px;
      font-size: 25px;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  .close{
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
    @media(max-width: 520px){
      right: 12px;
      top: 20px;
    }
  }
}

@keyframes scalingBack {
	From {
		-webkit-transform: scale(1.0);
	}
	To {
		-webkit-transform: scale(0);
	}

}
.wrapSubmit {
  width: 100%;
  display: flex;
  justify-content: center;
  
}
@keyframes scaling {
	// From {
	// 	transform: scale(1.0);
	// }
	// To {
	// 	transform: scale(1.15);
	// }
  From {
		transform: rotate(0);
	}
	To {
		transform: rotate(360deg);
	}
}
.wrapContentMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.wrapSites {
  width: 85%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  @media(max-width: 1030px){
    width: 90%;
    margin-top: 26px;
  }
  .wrapTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
    @media(max-width: 1030px){
      margin-bottom: 4px;
    }
    .title {
      font-size: 34px;
      color: #de3a11;
      font-weight: 600;
      font-family: 'Lobster', cursive;
      font-display: swap;
      margin-bottom: 20px;
      @media(max-width: 520px){
        font-size: 26px;
        margin-bottom: 16px;
      }
    }
    
  }
  .wrapSite {
    display: flex;
    flex-direction: row;
    min-width: 940px;
    height: auto;
    position: relative;
    margin-bottom: 30px;
    @media(max-width: 1030px){
      max-width: 100%;
      min-width: 100px;
      flex-direction: column;
      margin-bottom: 18px;
    }
    .site {
      display: flex;
      min-width: 20%; 
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media(max-width: 520px){
        min-width: 100%;
      }
      .text {
        font-size: 30px;
        color: #de3a11;
        font-family: 'Lobster', cursive;
        font-display: swap;
        @media(max-width: 520px){
          font-size: 24px;
        }
      }
    }
    .contentSite {
      padding-right: 150px;
      padding-left: 30px;
      @media(max-width: 1030px){
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 15px;
        padding-right: 0px;
        padding-left: 0px;
      }
      .text {
        color: #4F443B;
        font-size: 22px;
        font-family: 'Lobster', cursive;
        font-display: swap;
        @media(max-width: 520px){
          font-size: 20px;
        }
      }
    }
    .wrapBtnSite {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      text-align: center;
      right: 0px;
      @media(max-width: 1030px){
        position: relative;
        width: 100%;
      }
      .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 40px;
        border-radius: 8px;
        font-weight: 600;
        color: gray;
        cursor: pointer;
        font-size: 26px;
        color: #4F443B;
        text-align: center;
        font-family: 'Lobster', cursive;
        font-display: swap;
        margin-top: 10px;
        box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
        @media(max-width: 1030px){
          width: 120px;
          height: 36px;
          font-size: 24px;
        }
        @media(max-width: 520px){
          width: 110px;
          height: 35px;
          font-size: 20px;
        }
        &:hover {
          color: #de3a11;
        }
        &:active {
          box-shadow: 0 5px gray;
          transform: translateY(8px);
        }
      }
    }
  }
}
.wrapTitlePortfolio {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  @media(max-width: 520px){
    margin-top: 10px;
  }
  .titlePortfolio {
    display: flex;
    justify-content: center;
    .text {
      font-size: 34px;
      color: #de3a11;
      font-family: 'Lobster', cursive;
      font-display: swap;
      font-weight: 600;
      @media(max-width: 1000px){
        font-size: 28px;
      }
      @media(max-width: 520px){
        font-size: 26px;
      }
    }
  }
}
.wrapTitleDetails {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  .titleDetails {
    display: flex;
    justify-content: flex-end;
    .text {
      font-size: 30px;
      color: gray;
      font-family: 'Lobster', cursive;
      font-display: swap;
      cursor: pointer;
      border-bottom: 3px solid gray;
      &:hover {
        color: #de3a11;
        border-bottom: 3px solid #de3a11;
      }
    }
  }
}

.link {
	text-decoration: none;
}

