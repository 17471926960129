@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
.wrapArticles {
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    background-color: #ffffff;
    border-radius: 12px;
    margin-bottom: 18px;
    padding-bottom: 24px;
    @media(max-width: 1030px){
        width: 95%;
        margin-top: 20px;
    }
    .wrapTytle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50px;
        .tytle {
            font-size: 34px;
            color: #de3a11;
            font-family: 'Lobster', cursive;
            margin-top: 18px;
            font-weight: 600;
            @media(max-width: 1030px){
              font-size: 26px;
            }
        }
    }
    %contentWrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin: 20px;
        margin-bottom: 0;
        //border: 2px solid #4F443B;
        box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 5);
        border-radius: 8px;
        //transition: all 1s ease;
    }
    .tytleWrap {
        @extend %contentWrapper;
        height: 60px;
        @media(max-width: 410px){
            height: 80px;
        }
    }
    .showContent {
        @extend %contentWrapper;
        height: 100%;
    }
    .tytle {
        font-size: 28px;
        color: #4F443B;
        font-family: 'Lobster', cursive;
        margin-top: 18px;
        margin-left: 25px;
        font-weight: 600;
        @media(max-width: 1030px){
          font-size: 24px;
        }
        @media(max-width: 520px){
            font-size: 20px;
            margin-left: 20px;
            margin-right: 10px;
            margin-top: 6px;
        }
    }
    .text {
        margin: 25px;
        color: #4F443B;
        font-family: 'Lobster', cursive;
        font-size: 22px;
        font-weight: 500;
        transition: all 1s ease;
        @media(max-width: 520px){
            font-size: 17px;
            
        }
    }
    .wrapContent {
        display: flex;
        .wrapText {
            display: flex;
            flex-direction: column;
        }
        .content {
            color: #4F443B;
            font-family: 'Lobster', cursive;
            margin-left: 20px;
            font-size: 23px;
            font-weight: 600;
            @media(max-width: 520px){
                font-size: 19px;
                margin-left: 12px;
            }
        }
        .text {
            color: #4F443B;
            font-weight: 500;
            font-size: 22px;
            margin-top: 0;
            @media(max-width: 520px){
                font-size: 17px;
                margin-left: 12px;
            }
        }
    }  
}
.circle {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-top: 5px;
}