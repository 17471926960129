@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
.wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 60px;
    background-color: #4F443B;
    @media(max-width: 520px){
        width: 100%;
    }
    .line {
        position: absolute;
        bottom: 55px;
        height: 2px;
        width: 100%;
        background-color: white;
    }
    .wrapContent {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        margin-top: auto;
        .phone {
            color: white;
            font-family: 'Limelight';
            font-display: swap;
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;
            text-decoration: none;
            .tag:before{
                color: white;
                font-size: 24px;
                font-weight: 500;
                content: "PHONE";
                font-family: 'Limelight';
                font-display: swap;
            }
            @media(max-width: 520px){
                font-size: 19px;
                .tag:before {
                    display: none;
                }
            }
        }
    }
}