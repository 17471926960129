
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
%wrapImg{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  .blocImg {
    display: flex;
    flex-direction: row;
    width: 660px;
    @media(max-width: 1030px){
      width: 100%;
      justify-content: space-around;
    }
    .block {
      display: flex;
      flex-direction: column;
      margin-bottom: 6px;
      .text {
        font-size: 26px;
        color: #4F443B;
        font-family: 'Lobster', cursive;
        font-display: swap;
        @media(max-width: 840px){
          font-size: 20px;
          margin-right: 0px;
        }
        @media(max-width: 580px){
          font-size: 16px;
          margin: 3px;
      }
      }
      .img {
        margin-right: 16px;
        box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 5);
        border-radius: 12px;
        @media(max-width: 840px){
          width: 260px;
          height: 146px;
          border-radius: 6px;
          margin-right: 0px;
        }
        @media(max-width: 580px){
          margin: 3px;
          width: 150px;
          height: 85px;
        }
        &:hover {
          box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
        }
        &:focus {
          box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
        }
        &:active {
          transform: translateY(3px);
        }
      }
    }
  }
}
.wrapCalc {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  height: 100%;
  margin-bottom: 20px;
  @media(max-width: 1030px){
    width: 95%;
  }
  .wrapNums {
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    margin-top: 5px;
    @media(max-width: 1030px){
      margin-left: 0px;
      justify-content: center;
    }
  }
  .wrapTitle {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 22px;
    @media(max-width: 1030px){
      margin-top: 20px;
    }
    @media(max-width: 840px){
      margin-top: 16px;
    }
    .title {
      font-size: 33px;
      color: #4F443B;
      font-family: 'Lobster', cursive;
      font-display: swap;
      @media(max-width: 1030px){
        font-size: 28px;
      }
      @media(max-width: 520px){
        font-size: 25px;
      }
    }
    .titleColor {
      font-size: 34px;
      color: #de3a11;
      font-family: 'Lobster', cursive;
      font-weight: 600;
      @media(max-width: 1030px){
        font-size: 28px;
      }
      @media(max-width: 520px){
        font-size: 26px;
        text-align: center;
      }
    }
  }
  .calc {
    min-width: 920px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: 0px 10px 13px 3px rgba(0, 0, 0, 5);
    border-radius: 14px;
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(238, 163, 23, 0.1);
    @media(max-width: 1030px){
      padding: 0px;
    }
    @media(max-width: 960px){
      min-width: 0;
      flex-direction: column;
    }
    .content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .wrapImg {
        @extend %wrapImg;
      }
      .moveWrapImg {
        @extend %wrapImg;
        animation: arrowAnimate 0.5s forwards;;
      }
      .wrapIcons {
        display: flex;
        flex-direction: row;
        width: 608px;
        justify-content: center;
        margin-bottom: 3px;
        @media(max-width: 1030px){
          width: 100%;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .icons {
          font-size: 40px;
			    color: #de3a11;
          &:active {
            transform: translateY(4px);
          }
        }
        .closeIcons {
          font-size: 34px;
			    color: #de3a11;
          padding-top: 3px;
          padding-left: 18px;
          padding-right: 18px;
          &:active {
            transform: translateY(4px);
          }
        }
      }
    } 
    .result {
      display: flex;
      flex-direction: column;
      width: 300px;
      height: 390px;
      box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 5);
      border-radius: 8px;
      margin-top: 30px;
      background-image: url(images/stars2.gif);
      background-size: cover; 
      background-position: center center;
      &:hover {
        box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
      }
      @media(max-width: 1160px){
        width: 260px;
      }
      @media(max-width: 960px){
        margin-top: 8px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        height: auto;
        min-height: 100px;
      }
      @media(max-width: 520px){
        margin-left: auto;
        margin-right: auto;
        width: 55%;
      }
      .wrapText {
        height: 85%;
        display: flex;
        flex-direction: column;
        @media(max-width: 840px){
          flex-direction: column;
        }
        .title {
          color: #4F443B;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40px;
          margin-bottom: 8px;
          font-size: 28px;
          //background-color: #4F443B;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          font-family: 'Lobster', cursive;
          font-display: swap;
          font-weight: 500;
          @media(max-width: 840px){
            height: 34px;
            font-size: 24px;
            font-weight: 500;
          }
        }
        .text {
          color: #4F443B;
          font-family: 'Lobster', cursive;
          font-display: swap;
          font-size: 21px;
          @media(max-width: 840px){
            font-size: 19px;

          }
        }
      }
      .wrapBtn {
        width: 100%;
        display: flex;
        justify-content: center;
        .btn{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 98px;
          height: 38px;
          box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 5);
          border-radius: 8px;
          cursor: pointer;
          @media(max-width: 840px){
            width: 80px;
            height: 36px;
            margin-bottom: 8px;
          }
          &:hover {
            box-shadow: 0px 3px 5px 1px rgba(222, 58, 17, 1);
          }
          &:active {
            box-shadow: 0 2px gray;
            transform: translateY(4px);
          }
          .title {
            color: #4F443B;
            font-size: 24px;
            font-family: 'Lobster', cursive;
            font-display: swap;
            margin-bottom: 8px;
            @media(max-width: 840px){
              font-size: 22px;
            }
            &:hover {
              color: #fb6a16;
            }
            
          }
        }
      }
    }
  } 
}

.text {
  font-size: 18px;
  margin-left: 20px;
  margin-bottom: 6px;
  color: #4F443B;
}

.wrapModal {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 253, 208, 0.9);
  border: 2px solid #fb6a16;
  border-radius: 8px;
  padding-left: 20px;
  padding: 20px;
  .wrapTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    .title {
      font-weight: 600;
      font-size: 22px;
      font-family: 'Lobster', cursive;
      font-display: swap;
      color: #4F443B;
    }
    .close{
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
  } 
}
.title {
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  color: rgb(42, 46, 46);
  font-family: 'Lobster', cursive;
  font-display: swap;
}
.textarea {
  width: 280px;
  height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 2px solid rgba(67, 79, 82);
  font-weight: 500;
  font-size: 16px;
  font-family: 'Lobster', cursive;
  font-display: swap;
  color: rgb(42, 46, 46);
  background-color: rgba(238, 163, 23, 0);
}
.wrapSubmit {
  width: 100%;
  display: flex;
  justify-content: center;
}
@keyframes scaling {
	From {
    opacity: 1;
	}
	To {
    opacity: 0.3;
	}
}
@keyframes arrowAnimate{
  from { 
    opacity: 1;
}             
  to { 
    opacity: 0.3;
  }	
}