.envelop {
    width: 29px;
    height: 140px;
    background-color: #ff9a00;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    @media(max-width: 1030px){
      width: 25px;
      height: 110px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      top: 320px;
    }
    .message {
      color: white;
      transform: rotate(-90deg);
      font-family: 'Limelight';
      font-size: 23px;
      font-weight: 400;
      cursor: pointer;
      @media(max-width: 520px){
        font-size: 19px;
      }
    }
  }