.form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    .input {
      border: none;//2px solid rgba(67, 79, 82);
      background-color: rgba(238, 163, 23, 0);
      //border-radius: 8px;
      border-bottom: 2px solid rgba(67, 79, 82);
      width: 280px;
      height: 16px;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px;
      font-family: 'Lobster', cursive;
      color: rgb(42, 46, 46);
      outline: 0px;
      margin-bottom: 12px;
    }
    input[type="submit"] {
      width: 140px;
      height: 35px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 6px;
      margin-top: 12px;
      border: 2px solid #de3a11; 
      border-radius: 8px;
      font-weight: 600;
      color: gray;
      cursor: pointer;
      font-size: 20px;
      font-family: 'Lobster', cursive;
      font-weight: 500;
      color: #de3a11; 
      &:hover {
        color: #fb6a16;
      }
      &:active {
        box-shadow: 0 5px gray;
        transform: translateY(4px);
      }
    }
    .textarea {
      width: 280px;
      height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 2px solid rgba(67, 79, 82);
      font-weight: 500;
      font-size: 16px;
      font-family: 'Lobster', cursive;
      color: rgb(42, 46, 46);
      background-color: rgba(238, 163, 23, 0);
    }
    .label {
      margin-top: 5px;
      margin-bottom: 2px;
      font-size: 18px;
      font-weight: 500;
      color: #de3a11;
      font-family: 'Lobster', cursive;
    }
}